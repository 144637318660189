import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from 'react-modal';
import MaterialIcon from 'material-icons-react';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}
const FeaturesTiles = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      background: '#292929',
      border: '0'
    },
  };
  
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalArticlesIndex, setModalArticlesIndex] = useState(0);

  const openModal = (index) => {
    setModalArticlesIndex(index)
    setIsOpen(true);
    document.body.classList.add('inactive-scroll')
  }

  const closeModal = () => {
    setIsOpen(false);
    document.body.classList.remove('inactive-scroll')
  }

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap center-content',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'My Experience',
    paragraph: ''
  };

  const experienceDatas = [
    {
      image: require('./../../assets/images/indivara.jpg'),
      company: "Indivara Group",
      title: "Web Developer",
      date: "Jul - Des 2018",
      responsibilty: "Developing airline ticket agent web apps for business travel using VueJS and Codeigniter.",
      achievement: 
      <>
      <p>
        The description of the application cannot be published because it is internal to the company
      </p>
      </>
    },
    {
      image: require('./../../assets/images/hrdpintar.jpg'),
      company: "Hrdpintar.com",
      title: "iOS Engineer & Web Developer",
      date: "Jan - Jul 2019",
      responsibilty: "Building from scratch hrdpintar apps without special architecture and developing HRIS web apps.",
      achievement:
      <>
      <p>
        Build from scratch hrdpintar iOS apps without special architecture and developing Web Apps for end user and dashboard for admin.
      </p> 
      <p>
        You can see the iOS Apps in AppStore <a href="https://apps.apple.com/us/app/hrdpintar-eslip/id1463004118?l=id&platform=iphone">here</a><br/>
        and Web Apps in <a href="https://hrdpintar.com/">www.hrdpintar.com</a>
      </p>
      </>
    },
    {
      image: require('./../../assets/images/bareksa.jpg'),
      company: "Bareksa.com",
      title: "Web Engineer",
      date: "Aug 2019 - Jun 2020",
      responsibilty: 
      <>
        building a few web apps from scratch and developing features using <a href="https://reactjs.org/docs">ReactJS</a> for CSR and <a href="https://nextjs.org/docs/">NextJS </a> for SSR.
      </>,
      achievement:
      <>
      <p>
        Collaborated with the Frontend team, Backend, Designer, and Product team to revamp the Bareksa web homepage using the server side rendering  (SSR) method.
      </p>
      <p>
        Collaborated with the Frontend, Backend, Designer, and Product team to build Bareksa prioritas web apps from scratch.
      </p>
      <p>
        Collaborated with the Frontend team to build website and UI component design system with Storybook
      </p>
      <p>
        As a Web Frontend Engineer, collaborate with Backend, Designer, Mobile Apps, and Product team to build Surat Berharga Negara (SBN) webviews in the Bareksa Mobile Application.
      </p>
      </>
    },
    {
      image: require('./../../assets/images/bareksa.jpg'),
      company: "Bareksa.com",
      title: "iOS Engineer",
      date: "Jul 2020 - Now",
      responsibilty: "building a few product and feature on Bareksa iOS App using Swift programming language with MVVM Architecture.",
      achievement: 
      <>
      <p>
        Collaborated with the iOS team to develop apps with Swift MVVM Architecture, third party library, unit test, and give each other code reviews.
      </p> 
      <p>
        Collaborated with the iOS, Backend, Designer, and Product team to build, develop, and become the person in charge of IOS for the Robo Advisor features.
      </p>
      <p>
        Collaborated with the iOS, Backend, Designer, and Product team to revamp Bareksa transaction flow.
      </p>
      <p>
        As an iOS Engineer, collaborate with Backend, Designer,  and Product team to build, develop, and become the person in charge of IOS for develop new voucher flow.
      </p>
      <p>
        Collaborated with the iOS team to build component  design system.
      </p>
      <p>
        Have the responsibility to be a mentor for interns.
      </p>
      </>
    },
  ]
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <Modal
        isOpen={modalIsOpen}
        style={customStyles}
        closeTimeoutMS={100} 
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <MaterialIcon
        icon="close"
        color="#4c4c4c"
        size={20} 
        onClick={closeModal}
        />
        <div className="experience-modal-content">
          <h5>{experienceDatas[modalArticlesIndex].title}</h5>
          <h6>at {experienceDatas[modalArticlesIndex].company}</h6>
          <div className="experience-modal-content-articles">
            {experienceDatas[modalArticlesIndex].achievement}
          </div>
        </div>
      </Modal>

      <div className="container experience">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

          {
            experienceDatas.map((experience, index) =>
            <div key={experience.title} className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner">
                <div className="features-tiles-item-header">
                  <div className="features-tiles-item-image mb-16">
                    <Image
                      src={experience.image}
                      alt="Features tile icon 01"
                      width={64}
                      height={64} />
                  </div>
                </div>
                <div className="features-tiles-item-content">
                  <h4 className="mt-0 mb-0">
                    {experience.company}
                  </h4>
                  <h6 className="mt-0 mb-0">
                    {experience.title}
                  </h6>
                  <span className="mt-0 mb-8">
                    {experience.date}
                  </span>
                  <p className="m-0">
                    {experience.responsibilty}
                  </p>
                  <ButtonGroup>
                    <Button tag="a" onClick={() => openModal(index)} color="primary" wideMobile>
                      <span>Details</span> <MaterialIcon icon="arrow_forward" color="#F5F5F5" size={20} />
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
            )
          }
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;