import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { Container, Row, Col } from 'react-grid-system';
import MaterialIcon from 'material-icons-react';

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {

  const outerClasses = classNames(
    'cta section reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  );  

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h5 className="m-0">
              Don't hesitate to try reach out !
            </h5>
            <Container className="contact">
              <Row>
                <Col sm={12}>
                  <Row>
                    <Col xs={12}>
                      <div className="contact-icon">
                      <MaterialIcon
                        icon="call"
                        color="#F5F5F5"
                        size={18} 
                      />
                      </div>
                      <span>
                        +62 896 6477 5546
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12}>
                  <Row>
                    <Col xs={12}>
                      <div className="contact-icon">
                        <MaterialIcon
                          icon="mail"
                          color="#F5F5F5"
                          size={18} 
                        />
                      </div>
                      <span>
                        anjasdwi.id@gmail.com
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </section>
  );
}

Cta.propTypes = propTypes;
Cta.defaultProps = defaultProps;

export default Cta;