import React from 'react';
import classNames from 'classnames';
import { SkillsProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import { Container, Row, Col } from 'react-grid-system';
import Image from '../elements/Image';

const propTypes = {
  ...SkillsProps.types
}

const defaultProps = {
  ...SkillsProps.defaults
}

const Skills = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-tiles-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const sectionHeader = {
    title: 'Programming Skills',
    paragraph: ''
  };

  const skillsItem = [
    {
      img: require('./../../assets/images/swift.png'),
      name: "Swift",
      level: 75
    },
    {
      img: require('./../../assets/images/js.png'),
      name: "Javascript (ReactJS, NextJS)",
      level: 70
    },
    {
      img: require('./../../assets/images/php.png'),
      name: "PHP (CodeIgniter)",
      level: 50
    },
    {
      img: require('./../../assets/images/html.png'),
      name: "HTML, CSS",
      level: 75
    },
    {
      img: require('./../../assets/images/git.png'),
      name: "Versioning Git",
      level: 70
    },
    {
      img: require('./../../assets/images/android.png'),
      name: "Android Java",
      level: 20
    }
  ];

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <Container>
            <Row>
              {
                skillsItem.map((skill) =>
                <Col key={skill.name} sm={4} className="container-skills-item">
                  <Row>
                    <Col xs={3} className="skills-item">
                    <Image 
                    src={skill.img}
                    alt="Open"
                    />
                    </Col>
                    <Col xs={9} className="ta-l">
                      {skill.name}
                      <div className="line-level">
                        <div
                        className="line-level-fill"
                        style={{
                          width: `${skill.level}%`
                        }}>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
                )
              }
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}

Skills.propTypes = propTypes;
Skills.defaultProps = defaultProps;

export default Skills;